
import { defineComponent, ref, watch, onMounted, Ref, computed } from "vue";

import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import moment from "moment";
import useEmitter from "@/composables/Emmiter";
import AuthService from "@/services/AuthService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/services/ApiService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import FilterBase from "@/layout/header/partials/filters/FilterBase.vue";
import Api from "@/services/Api";
import axios from "axios";
import { mode } from "crypto-js";

export default defineComponent({
  name: "filtro-veiculos",
  components: {
    FilterBase,
    ElConfigProvider,
  },

  setup(props, { emit }) {
    const emitter = useEmitter();
    const closeModal = ref(false);
    //variaveis de controle dos cards
    const openListCars = ref(false);
    const marcasDeVeiculos: Ref<any> = ref([]);
    const tiposDeProdutosRefEntrada: Ref<any> = ref([]);
    const tiposDeProdutosRef: Ref<any> = ref([]);
    const marcaSelecionada = ref("");
    const allModelos: Ref<any> = ref([]);
    const veiculoSelecionado = ref("");
    const yearInit: Ref<Number[]> = ref([]);
    const yearFinal: Ref<Number[]> = ref([]);
    const clientYearInit = ref("");
    const clientYearFinal = ref("");
    const modelsListFiltered: Ref<Array<any>> = ref([]);
    const modelsYearListFiltered: Ref<Array<any>> = ref([]);
    const allModelosFiltered: Ref<any> = ref([]);
    const inputTypeMotor = ref("");
    const fipeNumero: Ref<string> = ref("");
    const spanWarning = ref(false);
    const paginasNumeradas: Ref<any> = ref([]);

    //FUNCAO PARA PEGAR O TOKEN
    const getToken = async () => {
      return localStorage.getItem("token_usuario");
    };
    getToken();

    //FUNCAO PARA BUSCAR OS TIPOS DE PRODUTOS QUE PODEM SER CADASTRADOS
    async function getTipoProdutos() {
      try {
        const res = await axios.get("tipoProdutos", {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        });
        tiposDeProdutosRefEntrada.value = res.data;
        tiposDeProdutosRef.value = tiposDeProdutosRefEntrada.value.filter(
          product => product.nome.split("_")[0] != "oleo" && product.nome.split("_")[0] != "pneu"
        );
      } catch (err) {
        console.log(err);
      }
    }

    //FUNCAO QUE BUSCA AS MARCAS DOS CARROS EM NOSSA BASE
    async function getMarcas() {
      try {
        const res = await axios.get("marcas", {
          headers: {
            Authorization: `Bearer ${await getToken()}`,
          },
        });
        marcasDeVeiculos.value = res.data;
      } catch (err) {
        console.log(err);
      }
    }

    // sempre que o usuario escolhe uma marca, a funcao que tras o modelos filtrados ordenados e organizados é chamada.
    // as options sempre estaram carregadas.
    watch(
      () => marcaSelecionada.value,
      () => {
        yearInit.value = [];
        yearFinal.value = [];
        veiculoSelecionado.value = "";
        clientYearInit.value = "";
        clientYearFinal.value = "";
        fipeNumero.value = "";
        openListCars.value = false;
      }
    );

    watch(
      () => fipeNumero.value,
      () => {
        marcaSelecionada.value = "";
        yearInit.value = [];
        yearFinal.value = [];
        veiculoSelecionado.value = "";
        clientYearInit.value = "";
        clientYearFinal.value = "";
        openListCars.value = false;
      }
    );

    //FUNCAO QUE FILTRA OS MODELOS COM BASE NA SUA MARCA FABRICANTE
    async function getModelosPorMarcas(modelSelect: string) {
      yearInit.value = [];
      yearFinal.value = [];
      allModelosFiltered.value = [];
      try {
        const res = await axios.post(
          "modelosPorMarcas",
          { marca: modelSelect, fipe: !fipeNumero.value ? null : fipeNumero.value },
          {
            headers: {
              Authorization: `Bearer ${await getToken()}`,
            },
          }
        );
        allModelos.value = res.data;
        if (fipeNumero.value) return (modelsYearListFiltered.value = allModelos.value);

        allModelosFiltered.value = allModelos.value
          .map(family => family.modelo.split(" ")[0])
          .reduce((unico: any, item: any) => (unico.includes(item) ? unico : [...unico, item]), [])
          .sort(function Crescente(a: any, b: any) {
            return a > b ? 1 : a < b ? -1 : 0;
          });
      } catch (err) {
        console.log(err);
      }
    }

    watch(
      () => veiculoSelecionado.value,
      () => {
        yearInit.value = [];
        yearFinal.value = [];
        clientYearInit.value = "";
        clientYearFinal.value = "";
        filterModel(veiculoSelecionado.value);
      }
    );

    // esse hook watch, monitora toda vez que seleciona um novo fabricante. ele é acionado e ordena os modelos evitando a repetição do nome
    // das familias do veiculo (PALIO, GOL, ETC), mais na exibição da lista, é exibido todos. A familia so é exibida uma vez.

    //FILTRO PARA ORGANIZACAO DOS MODELOS BUSCADOS COM BASE NO ANO, DO MAIS ANTIGO PARA O MAIS NOVO
    async function filterModel(veiculoClicado: string) {
      yearInit.value = [];
      yearFinal.value = [];
      modelsListFiltered.value = await allModelos.value
        .filter(atual => atual.modelo.split(" ")[0].toLowerCase() == veiculoClicado.split(" ")[0].toLowerCase())
        .sort(Crescente);
      yearEnterOptions();
    }
    // Callback function de ordenação dos carros exibidos por ano de fabricação
    function Crescente(a, b) {
      return a.ano > b.ano ? 1 : a.ano < b.ano ? -1 : 0;
    }

    function filterYearModel() {
      const allCars = modelsListFiltered.value.filter(
        atual => Number(atual.ano.split(" ")[0]) >= Number(clientYearInit.value) && Number(atual.ano.split(" ")[0]) <= Number(clientYearFinal.value)
      );

      if (!inputTypeMotor.value) {
        return (modelsYearListFiltered.value = allCars);
      }
      if (inputTypeMotor.value.split(" ").length > 1) {
        return (modelsYearListFiltered.value = allCars.filter(
          motorOUvalvulas =>
            motorOUvalvulas.modelo.toLowerCase().includes(inputTypeMotor.value.split(" ")[0].toLowerCase()) &&
            motorOUvalvulas.modelo.toLowerCase().includes(inputTypeMotor.value.split(" ")[1].toLowerCase())
        ));
      }
      modelsYearListFiltered.value = allCars.filter(motor => motor.modelo.toLowerCase().includes(inputTypeMotor.value.toLowerCase()));
    }

    // essa função cria o ano inicial de busca de veiculo. ela sempre vai ter como base a data atual.
    // usado o for comum para ficar mais claro o funcionamento para qualquer um.
    // o array de modelos é ordenado anteriormente por ano, e por meio de acesso por posição do array, ele vira o primeiro ano
    // do modelo do carro disponivel. o ultimo disponivel mesma coisa, é a ultima posiçao do array de modelos.
    //a funcao de ano de saida, segue o mesmo padrao, acrescentando no ultimo ano +1 pra evitar problema com ano de fabricaçao e modelo diferentes.
    async function yearEnterOptions() {
      const initBaseYear = Number(modelsListFiltered.value[0]?.ano.split(" ")[0]);
      const initBaseYearFinal = Number(modelsListFiltered.value[modelsListFiltered.value.length - 1]?.ano.split(" ")[0]);
      for (let i = initBaseYear; i <= initBaseYearFinal; i++) {
        yearInit.value.push(i);
      }
      yearOutOptions();
    }

    // esse hook watch libera para o usuario o ano de saida do veiculo somente quando ele seleciona o ano de entrada.
    // de moto a evitar erros do usuario ou requisiçoes desnecessarias com dados errados.
    watch(
      () => clientYearInit.value,
      () => {
        yearOutOptions();
      }
    );

    function yearOutOptions() {
      const initBaseYearFinal = Number(modelsListFiltered.value[modelsListFiltered.value.length - 1]?.ano.split(" ")[0]);
      yearFinal.value = [];
      for (let i = Number(clientYearInit.value); i <= initBaseYearFinal + 1; i++) {
        yearFinal.value.push(i);
      }
    }

    //VERIFICAR INPUTS SELECIONADOS
    function verifySelectValues() {
      if (fipeNumero.value) return (spanWarning.value = false), (openListCars.value = true), getModelosPorMarcas("valorNulo");

      if (!marcaSelecionada.value || !veiculoSelecionado.value || !clientYearInit.value || !clientYearFinal.value) {
        spanWarning.value = true;
        return;
      }
      spanWarning.value = false;
      openListCars.value = true;
    }

    async function enviarEmit() {
      await emitter.emit("filtro-veiculos", {
        veiculosOUfipe: [modelsYearListFiltered.value, fipeNumero.value ? modelsYearListFiltered.value : modelsListFiltered.value],
      });
      closeModal.value = true;
    }

    function usePagination() {
      const state = ref({
        currentPage: 1,
        itemsPerPage: 20,
        itemsOnPage: 0,
      });

      const pageCount = computed(() => Math.ceil(modelsYearListFiltered?.value.length / state.value.itemsPerPage));

      const itemsOnPage = computed(() => {
        const startIndex = (state.value.currentPage - 1) * state.value.itemsPerPage;
        const endIndex = startIndex + state.value.itemsPerPage;
        return modelsYearListFiltered.value.slice(startIndex, endIndex);
      });

      const setCurrentPage = page => {
        state.value.currentPage = page;
        state.value.itemsOnPage = itemsOnPage.value.length;
      };

      return {
        state,
        setCurrentPage,
        pageCount,
        itemsOnPage,
      };
    }
    const { state, setCurrentPage, pageCount, itemsOnPage } = usePagination();

    const numberPages = () => {
      for (let i = 1; i <= pageCount.value; i++) {
        paginasNumeradas.value = i;
      }
    };
    watch(
      () => modelsYearListFiltered.value,
      () => enviarEmit()
    );

    onMounted(() => {
      getMarcas();
      getTipoProdutos();
    });

    return {
      openListCars,
      marcasDeVeiculos,
      tiposDeProdutosRefEntrada,
      tiposDeProdutosRef,
      marcaSelecionada,
      allModelos,
      veiculoSelecionado,
      yearInit,
      yearFinal,
      clientYearInit,
      clientYearFinal,
      modelsListFiltered,
      modelsYearListFiltered,
      allModelosFiltered,
      inputTypeMotor,
      fipeNumero,
      spanWarning,
      getModelosPorMarcas,
      filterYearModel,
      verifySelectValues,
      setCurrentPage,
      numberPages,
      enviarEmit,
      paginasNumeradas,
      closeModal,
    };
  },
});
